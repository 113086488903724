import { Link } from "react-router-dom";
import ServicesListData from "./ServicesListData";
import { Box, Card, CardContent, Typography } from "@mui/material";

const Services = () => {
  return (
    <div>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <Typography
              variant="h4"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Our Services
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  border: "1px solid black",
                  borderStyle: "dashed",
                  width: "10%",
                }}
              />
            </Box>
          </div>
          <div className="row">
            {ServicesListData.map((service, index) => (
              <div
                key={index}
                className="col-lg-4 col-md-6 icon-box"
                data-aos="fade-up"
                data-aos-delay={service.time}
              >
                <Card
                  sx={{
                    // maxWidth: { lg: "90%", md: "100%" },
                    margin: "auto",
                    borderRadius: "20px",
                    backgroundColor: "#FBF8F1",
                    my: 1,
                    transition: "box-shadow 0.3s",
                    "&:hover": {
                      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.6)",
                    },
                  }}
                  raised
                >
                  <CardContent>
                    <div className="icon">
                      <i className={service.icon}></i>
                    </div>
                    <h4 className="title">
                      <Link to="">{service.title}</Link>
                    </h4>
                    <p className="description">&nbsp;&nbsp;{service.desc}</p>
                  </CardContent>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};
export default Services;
