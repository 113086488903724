import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

//google Analytics code
import ReactGA from "react-ga";
ReactGA.initialize("G-360W97GTXJ");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  //  <React.StrictMode>
  <App />
  /* </React.StrictMode> */
);
