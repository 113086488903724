import UnderConstruction from "./UnderConstruction";
const CandidateReg = () => {
  return (
    <div className="container">
      <div className="row mx-0 justify-content-center">
        <div className="col-md-7 col-lg-5 px-lg-2 col-xl-4 px-xl-0 px-xxl-3">
          <form
            method="POST"
            className="
          w-100
          rounded-1
          p-4
          border
          text-secondary
          bg-dark
          border-secondary
        "
            action="#"
            enctype="multipart/form-data"
          >
            <label className="d-block mb-4">
              <span className="form-label d-block text-light">Your name</span>
              <input
                required
                name="name"
                type="text"
                className="form-control text-light border-secondary bg-transparent"
                placeholder="Joe Bloggs"
              />
            </label>

            <label className="d-block mb-4">
              <span className="form-label d-block text-light">
                Email address
              </span>
              <input
                required
                name="email"
                type="email"
                className="form-control border-secondary bg-transparent text-light"
                placeholder="joe.bloggs@example.com"
              />
            </label>

            <label className="d-block mb-4">
              <span className="form-label d-block text-light">
                Years of experience
              </span>
              <select
                required
                name="experience"
                className="form-select border-secondary bg-transparent text-light"
              >
                <option>Less than a year</option>
                <option>1 - 2 years</option>
                <option>2 - 4 years</option>
                <option>4 - 7 years</option>
                <option>7 - 10 years</option>
                <option>10+ years</option>
              </select>
            </label>

            <label className="d-block mb-4">
              <span
                className="
              form-label
              d-block
              text-light
              border-secondary
              bg-transparent
            "
              >
                Tell us more about yourself
              </span>
              <textarea
                name="message"
                className="form-control border-secondary bg-transparent text-light"
                rows="3"
                placeholder="What motivates you?"
              ></textarea>
            </label>

            <label className="d-block mb-4">
              <span className="form-label d-block text-light">Your CV</span>
              <input
                required
                name="cv"
                type="file"
                className="form-control border-secondary bg-transparent text-secondary"
              />
            </label>

            <div className="mb-4">
              <div>
                <div className="form-check">
                  <label className="d-block">
                    <input
                      type="radio"
                      className="form-check-input bg-transparent border-secondary"
                      name="remote"
                      value="yes"
                      checked
                    />
                    <span className="form-check-label text-light">
                      You'd like to work remotely
                    </span>
                  </label>
                </div>
              </div>
              <div>
                <label className="form-check">
                  <input
                    type="radio"
                    className="form-check-input border-secondary bg-transparent"
                    name="remote"
                    value="no"
                  />
                  <span className="form-check-label text-light">
                    You'd prefer to work onsite
                  </span>
                </label>
              </div>
            </div>

            <div className="mb-3">
              <button type="submit" className="btn btn-primary px-3 rounded-3">
                Apply
              </button>
            </div>
          </form>
          {/* <UnderConstruction /> */}
        </div>
      </div>
    </div>
  );
};

export default CandidateReg;
