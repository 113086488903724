import IndexHero from "./Hero";
import About from "./About";
import Services from "./Services/Services";
import Contact from "./Contact";
import Clients from "./Clients/ClientsView";

function MainPage() {
  // console.log("MainPage rendered");
  return (
    <div>
      <IndexHero />
      <About />
      <Clients/>
      <Services />
      <Contact />
    </div>
  );
}

export default MainPage;
