import React from "react";
import image404 from './Images/404.png';



export default function Error404() {
  return (
          <div>
          <section id="services" className="services">
            <div className="container" data-aos="fade-up">
              <div className="section-title">
                <h2>Page Not Found- 404 Error</h2>
              </div>
              <div className="row d-flex justify-content-center" >
                <h1 style={{textAlign:"center"}}> The Requested Page is Not Available!!</h1>
                <img alt="Error" src={image404} style={{height:"20rem", width:"20rem"}} />
              </div>
            </div>
          </section>
        </div>
  );
}
