import React from "react";
import image404 from "./Images/Construction.gif";

export default function UnderConstruction() {
  return (
    <div>
      <section id="services" className="services">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Page is Under Construction</h2>
          </div>
          <div className="row d-flex justify-content-center">
            <h1 style={{ textAlign: "center" }}>
              Content will be Available Soon.
            </h1>
            <img
              alt="Error"
              src={image404}
              style={{ height: "20rem", width: "20rem" }}
            />
            <h1 style={{ textAlign: "center" }}>Thanks for your Patience !</h1>
          </div>
        </div>
      </section>
    </div>
  );
}
