import { Box, Typography } from "@mui/material";

const About = () => {
  return (
    <section id="about" className="about">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            About Us
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                border: "1px solid black",
                borderStyle: "dashed",
                width: "11%",
              }}
            />
          </Box>
        </div>
        <div className="row no-gutters">
          <div className="col-lg-6 video-box">
            <img src="assets/img/about.jpg" className="img-fluid" alt="About" />
          </div>
          <div className="col-lg-6 d-flex flex-column justify-content-center about-content">
            <div className="section-title">
              <p style={{ textAlign: "justify" }}>
                &nbsp;&nbsp;We're a vibrant software firm, committed to
                pioneering inventive solutions that make processes
                straightforward and operations efficient. Our mission is to
                democratize technology, ensuring it is approachable and easy to
                use, thereby equipping businesses and organizations with the
                necessary tools to thrive and succeed.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                &nbsp;&nbsp;Our passion is not just to create software, but to
                conceive tools that inspire change, drive growth, and support
                our clients as they achieve their ambitions. Our team of
                seasoned professionals merges technical expertise with strategic
                insight, resulting in digital solutions that not only resolve
                the challenges at hand, but also anticipate future needs.
              </p>
              <br />
              <p style={{ textAlign: "justify" }}>
                &nbsp;&nbsp;We maintain an unwavering focus on customer success,
                shaping our software to the unique contours of each business we
                work with. With each project, we embrace the opportunity to
                transform complexity into simplicity, to turn abstract ideas
                into tangible results, and to help our clients navigate the
                digital landscape with confidence and ease.
              </p>
            </div>
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon">
                <i className="bx bx-fingerprint"></i>
              </div>
              <h4 className="title">
                <a href="">Software Development</a>
              </h4>
              <p className="description">
                We work closely with clients to understand their unique needs
                and goals, and then create custom software solutions tailored to
                meet those requirements. Our team of developers and designers
                work together to deliver innovative solutions that streamline
                processes and enhance efficiency.
              </p>
            </div>
            <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div className="icon">
                <i className="bx bx-gift"></i>
              </div>
              <h4 className="title">
                <a href="">Cloud Solutions</a>
              </h4>
              <p className="description">
                Our cloud-based solutions allow for seamless collaboration and
                real-time data access, empowering businesses to stay ahead of
                the competition. Our products are designed with scalability and
                security in mind, ensuring that our clients can grow with
                confidence.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
