import activeEventImage from "./Images/events/78thindependence.gif";

import { useState, useEffect } from "react";

// console.log("Modal is being rendered");

export default function Modal({ onClose }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const modalStyles = {
    "--bs-gutter-x": 0,
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    animation: "fadeIn 0.5s",
    backdropFilter: "blur(0.7rem)",
    zIndex: 1000,
  };

  const imageStyles =
    windowWidth <= 768
      ? { height: "80%", width: "90%" }
      : {
          width: "70%",
          margin: "auto",
          borderRadius: "15px",
          backdropFilter: "blur(5px)",
        };

  // const buttonStyles =
  //   windowWidth <= 768
  // ? { width: "60%", marginLeft: "auto", marginRight: "auto" }
  //     : { width: "30%", marginLeft: "auto", marginRight: "auto" };

  return (
    <div style={modalStyles} className="row">
      <style>
        {`
          @keyframes fadeIn {
            from { opacity: 0; }
            to { opacity: 1; }
          }     
          @keyframes slideIn {
            from { transform: translateY(-200px); }
            to { transform: translateY(0); }
          }
        `}
      </style>
      <center>
        <button
          onClick={onClose}
          style={{
            color: "transparent",
            backgroundColor: "transparent",
            border: "none",
          }}
        >
          <img src={activeEventImage} alt="Modal Content" style={imageStyles} />
        </button>
      </center>
    </div>
  );
}
