import accounts from "../Images/accounting.png";
const ServicesListData = [
  {
    icon: "bi bi-code-slash",
    title: "Website Design and Development",
    desc: "Our team of specialists is dedicated to creating a website that embodies your brand and attracts your intended demographic. We'll handle everything from bespoke designs to comprehensive development, guaranteeing a website that is speedy, secure, and user-friendly.",
    time: "0",
  },
  {
    icon: "bi bi-shop",
    title: "E-Commerce Development",
    desc: "We provide holistic e-commerce development solutions to fuel the expansion of your online venture. Our specialists will collaborate with you to construct a robust, easy-to-use platform that seamlessly interconnects with your current systems and workflows.",
    delay: "100",
  },
  {
    icon: "bi bi-browser-edge",
    title: "Web Application Development",
    desc: " We specialize in the development of bespoke web applications meticulously designed to address your unique requirements. Whether your needs encompass a straightforward web application or a complex, multifaceted solution, our wealth of expertise ensures the delivery of exceptional, premium-quality outcomes.",
    time: "200",
  },
  {
    icon: "bi bi-cloud-check-fill",
    title: "Web Hosting Services",
    desc: " Our robust, secure hosting platforms ensure maximum uptime, rapid load times, and seamless user experiences, underpinning the credibility of your digital footprint. We tailor our hosting packages to match your specific needs, providing you with an optimized, scalable infrastructure to support your website's growth and performance.",
    time: "300",
  },
  {
    icon: "bi bi-gear-fill",
    title: "Website Maintenance and Support",
    desc: "We provide comprehensive Website maintenance and Support services to ensure the continuous functionality and optimal performance of your website. Our dedicated team is committed to keeping your website up-to-date and running smoothly. We offer regular updates to incorporate the latest features and security patches, as well as promptly address any technical issues that may arise.",
    time: "400",
  },
  {
    icon: "bi bi-cash-coin",
    title: "Accounting Services",
    desc: "We provide comprehensive accounting services, including QuickBooks and Tally solutions. Our offerings cover bookkeeping, financial reporting, tax preparation, payroll, and accounts management. With expertise in QuickBooks and Tally, we ensure accurate, efficient, and compliant financial operations tailored to your business needs, allowing you to focus on growth while we handle your accounting needs.",
    time: "700",
  },
  {
    icon: "bi bi-database-fill-gear",
    title: "SAP Application Development",
    desc: "The process of designing a computer software or collection of applications to carry out the various functions that a business needs is known as application development. As a technology oriented Organization, we are dedicated, focused and more interested towards providing Solutions to any such a complex requirements.",
    time: "500",
  },
  {
    icon: "bi bi-megaphone-fill",
    title: "Digital Marketing",
    desc: "We provide an extensive array of result-oriented digital marketing services. Our experienced team collaborates with you to develop a tailored strategy that harnesses the power of various digital channels, including SEO, PPC, social media, and more, to generate substantial traffic, high-quality leads, and increased sales. We ensure that your digital marketing efforts deliver exceptional outcomes, enabling your business to thrive in the online landscape.",
    time: "600",
  },

  {
    icon: "bi bi-headset",
    title: "Customer Services",
    desc: "Our customer care services are dedicated to providing exceptional support and enhancing customer satisfaction. We offer personalized, responsive assistance to address inquiries, resolve issues, and provide solutions tailored to individual needs. Our team is committed to delivering timely, effective resolutions and proactive support, ensuring a seamless experience for every customer. By focusing on building strong relationships and understanding customer preferences, we aim to improve overall satisfaction and loyalty, making sure every interaction contributes to a positive and lasting impression of your brand.",
    time: "800",
  },
  {
    icon: "bi bi-exclamation-circle-fill",
    title: "On Demand Services",
    desc: "We also provide a flexible On-Demand Service option, tailoring our services according to customer-specific needs and choices. This customizability ensures we meet unique requirements and consistently deliver exceptional service, making your experience with us truly personalized and satisfactory.",
    time: "900",
  },
];
export default ServicesListData;
