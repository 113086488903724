import React from "react";
import { Link } from "react-router-dom";

const Formlisttable = () => {
  return (
    <div>
      <div style={{ marginTop: "100px" }}>
        <center>
          <table
            className="table  table-borderless"
            style={{
              marginBottom: "4rem",
              marginTop: "7rem",
              textAlign: "left",
              width: "70%",
              boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
            }}
          >
            <tbody>
              <tr>
                <td>
                  👨🏻‍💼 <Link to="#">CPA</Link>
                </td>
                <td>📦 Courier services</td>
                <td>⚓ Ports</td>
                <td>🧪 Chemicals</td>
              </tr>
              <tr>
                <td>
                  🚗<Link to="/Automakers">Automakers</Link>{" "}
                </td>
                <td>⚕️ Healthcare</td>
                <td>💼 professional services</td>
                <td>💰 Investment</td>
              </tr>
              <tr>
                <td>✈️ Airlines</td>
                <td>🍺 Alcoholic beverages</td>
                <td>🍽️ Food</td>
                <td>📡 Telecommunication</td>
              </tr>
              <tr>
                <td>🛫 Airports</td>
                <td>🥤 Beverages</td>
                <td>🍔 Restaurant chains</td>
                <td>🏬 Retail</td>
              </tr>
              <tr>
                <td>✈️ Aircraft manufactures</td>
                <td>🥻 Clothing</td>
                <td>💻 Software</td>
                <td>🌐 Internet</td>
              </tr>
              <tr>
                <td>🏦 Banks</td>
                <td>⛏️ Mining</td>
                <td>📟 Semiconductors</td>
                <td>🏗️ Construction</td>
              </tr>
              <tr>
                <td>🏨 Hotels</td>
                <td>🚇 Railways</td>
                <td>🚬 Tobacco</td>
                <td>🎮 Video Game</td>
              </tr>
              <tr>
                <td>💊 Pharmaceuticals</td>
                <td>🏦 Insurance</td>
                <td>💳 Finanical services</td>
                <td>💻 Tech</td>
              </tr>
              <tr>
                <td>🛒 E-Commerece</td>
                <td>🏡 Real estate</td>
                <td>⛽ Oil&GAS</td>
                <td>🧠 AI</td>
              </tr>
              <tr>
                <td>📰 Media/Press</td>
                <td>⚡Electricity</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </center>
      </div>
    </div>
  );
};

export default Formlisttable;
