import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import ReactGA from "react-ga";
const Footer = () => {
  // console.log("Component Mounted/Rendered");
  // const [formData, setFormData] = useState({
  //   name: "Thulasi Solutions",
  //   email: "",
  //   subject: "Thanks for Subscribe!",
  //   message:
  //     "Thanks for Subscribing to our Newsletter Service. Your will get our latest Update instantly!",
  // });
  const [formData, setFormData] = useState({ email: "" });

  // Count
  const [count, setCount] = useState(0);
  useEffect(() => {
    incrementCount();
  }, []);

  const incrementCount = async () => {
    try {
      // alert("Increment function called");
      const response = await axios.post(
        "https://thulasisolutions.in:5000/increment",
        { source: "ts" }
        // "https://localhost:5000/increment"
      );
      setCount(response.data.ts_count);
    } catch (error) {
      console.error("Error incrementing count:", error);
    }
  };

  // Google Analytics
  useEffect(() => {
    // Track page view when the component mounts
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    const payload = {
      email: formData.email,
      timeStamp: new Date().toISOString().replace("T", " ").substring(0, 19),
    };
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://thulasisolutions.in:5000/subscribe",
        // "https://localhost:5000/subscribe",
        payload
      );
      alert("Thank You For Subscribing!");
      resetForm();
    } catch (error) {
      if (error.response.status === 409) {
        alert("Already Subscribed.");
      } else {
        alert("Error sending email: " + error.message);
      }
      console.log(error);
      // alert("Issue With Server. Please Comtact us @ thulasisolutions.in");
    }
  };
  const resetForm = () => {
    console.log("reset is called");
    setFormData((prevState) => ({
      ...prevState,
      email: "",
    }));
  };
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="" style={{ padding: "5rem" }}>
          <div className="row">
            <div className="col-lg-2 col-md-6 footer-info">
              <h3>Thulasi Solutions</h3>
              <p>
                <a
                  href="https://maps.app.goo.gl/A9KssBk1JMShSFs99"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Chirala, Bapatla
                  <br />
                  AP 523155, INDIA
                </a>
                <br />
                <br />
                <strong>Phone:</strong>{" "}
                <a href="tel:+91 9963801122">+91 9963801122</a>
                <br />
                <strong>Email:</strong>{" "}
                <a href="mailto:thulasisolutions@outlook.com">
                  thulasisolutions@outlook.com
                </a>
                <br />
              </p>
              <div className="social-links mt-3">
                <Link to="" target="_blank" className="twitter">
                  <i className="bx bxl-twitter"></i>
                </Link>
                <Link to="#" target="_blank" className="facebook">
                  <i className="bx bxl-facebook"></i>
                </Link>
                <Link to="#" target="_blank" className="instagram">
                  <i className="bx bxl-instagram"></i>
                </Link>
                <Link to="#" target="_blank" className="google-plus">
                  <i className="bx bxl-skype"></i>
                </Link>
                <Link to="#" target="_blank" className="linkedin">
                  <i className="bx bxl-linkedin"></i>
                </Link>
                <Link
                  to="#"
                  className="Count"
                  title={`Current count: ${count}`}
                >
                  <i className="">C</i>
                </Link>
              </div>
            </div>
            <div className="col-lg-1 col-md-6 footer-links">
              <h4>Useful Links</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/About">About us</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Clients">Clients</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/UD">Terms of service</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/UD">Privacy policy</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-6 footer-links">
              <h4>Our Services</h4>
              <ul>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Web Design and Development</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">E-Commerce Development</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Web Application Development</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Web Hosting Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Maintenance & Support</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Accounting Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">SAP Application Development</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Digital Marketing</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">Customer Services</Link>
                </li>
                <li>
                  <i className="bx bx-chevron-right"></i>{" "}
                  <Link to="/Services">On Demand Services</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-7 col-md-6 footer-newsletter">
              <div className="iframe-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3838.5878923625646!2d80.3588552!3d15.825669399999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a4a450e25f737fd%3A0xd68c56ffb52c5b98!2sThulasi%20Solutions!5e0!3m2!1sen!2sin!4v1721710273033!5m2!1sen!2sin"
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
              {/* <h4>Our Newsletter</h4>
              <p>Speak directly to one person in your audience</p>
              <form onSubmit={handleSubmit} method="POST">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <input type="submit" value="Subscribe" />
              </form> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Thulasi Solutions</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by{" "}
          <Link to="https://www.thulasisolutions.in">Thulasi Solutions</Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
