import React, { useEffect, useState, useRef } from "react";
import Webcam from "webcamjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Formlisttable from "./Formlisttable";

import img from "./ashoka-chakra.png";
import "./Login.css";

const Login = (props) => {
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [snapshotData, setSnapshotData] = useState(null);
  const [enteredUsername, setEnteredUsername] = useState(""); // State for entered username
  const [enteredPassword, setEnteredPassword] = useState(""); // State for entered password
  const passwordRef = useRef(null);
  const myCameraInnerRef = useRef(null);

  useEffect(() => {
    Webcam.set({
      width: 150,
      height: 150,
      image_format: "jpeg",
      jpeg_quality: 90,
    });
    Webcam.attach(myCameraInnerRef.current);
    return () => {
      Webcam.reset();
    };
  }, []);

  const [auth, setAuth] = useState(false);
  if (auth) {
    return <Formlisttable/>
    
  }

  const showPassword = () => {
    setIsPasswordShown(!isPasswordShown);
  };

  const take_snapshot = () => {
    Webcam.snap(function (data_uri) {
      setSnapshotData(data_uri);
      console.log("Snapshot taken:", data_uri);
    });
  };

  const handleLogin = () => {
    const expectedUsername = "dataforms";
    const expectedPassword = "1234";
    if (
      enteredUsername === expectedUsername &&
      enteredPassword === expectedPassword
    ) {
      setAuth(true);
    } else {
      alert("Invalid username or password");
    }
  };

  return (
    <div className="divClass">
      <div className="container1 mt-5 card1">
        <div id="my_camera">
          <div className="inner" ref={myCameraInnerRef}></div>
        </div>
        <h2 className="text-center">Sign In</h2>
        <form>
          <div className="form-group">
            <label className="control-label" htmlFor="username">
              User Name
            </label>
            <input
              type="text"
              placeholder="User Name"
              title="Please enter your username"
              required
              name="username"
              id="username"
              className="form-control"
              onChange={(e) => setEnteredUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label className="control-label" htmlFor="password">
              Password
            </label>
            <div className="input-group">
              <input
                type={isPasswordShown ? "text" : "password"}
                className="form-control"
                id="password"
                name="password"
                placeholder="******"
                required
                ref={passwordRef}
                onChange={(e) => setEnteredPassword(e.target.value)}
              />
              <div className="input-group-append">
                <button
                  id="show_password"
                  className="btn btn-outline-secondary"
                  type="button"
                  onClick={showPassword}
                >
                  <FontAwesomeIcon
                    icon={isPasswordShown ? faEye : faEyeSlash}
                  />
                </button>
              </div>
            </div>
          </div>
          <div
            className="form-group mt-3"
            style={{ transform: "translate(0,24px)" }}
          >
            <input
              type="button"
              className="btn btn-block"
              value="Take Snapshot"
              onClick={() => {
                take_snapshot();
                console.log("Snapshot taken!");
              }}
              style={{ backgroundColor: "#FF9933", width: "100%" }}
            />
          </div>
          {snapshotData && (
            <input
              type="hidden"
              name="image"
              className="image-tag"
              value={snapshotData}
            />
          )}
          <div align="center">
            <img src={img} alt="ashok chakra" style={{ marginTop: "25px" }} />
          </div>
          <div
            className="form-group mt-3"
            style={{ transform: "translate(0,-14px)" }}
          >
            <button
              type="button"
              name="submit"
              className="btn btn-success btn-block"
              id="loginButton"
              disabled={!snapshotData}
              style={{ width: "100%" }}
              onClick={handleLogin}
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
