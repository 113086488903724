import React, { useState } from "react";
import axios from "axios";
import { Box, Typography } from "@mui/material";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      ...formData,
      message: formData.message,
      subject: formData.subject,
      timeStamp: new Date().toISOString().replace("T", " ").substring(0, 19),
    };

    try {
      const response = await axios.post(
        "https://thulasisolutions.in:5000/contactus",
        // "https://localhost:5000/contactus",
        dataToSend
      );
      alert("Thank You For Contacting Us!");
      resetForm();
    } catch (error) {
      // alert("Error sending email: " + error.message);
      alert(
        "Issue With Server. Please Contact Us @ thulasisolutions@outlook.com"
      );
    }
  };

  const resetForm = () => {
    setFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
    });
  };
  return (
    <section id="contact" className="contact">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <Typography
            variant="h4"
            sx={{ fontWeight: "bold", textAlign: "center" }}
          >
            Contact @
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                border: "1px solid black",
                borderStyle: "dashed",
                width: "11%",
              }}
            />
          </Box>
        </div>
        <div className="row">
          <div className="col-lg-6 d-flex" data-aos="fade-up">
            <div className="info-box">
              <i className="bx bx-map"></i>

              <h3>Our Address</h3>
              <a
                href="https://maps.app.goo.gl/A9KssBk1JMShSFs99"
                target="_blank"
              >
                <p>
                  D.No: 131, Street Number: 800, First Floor, Wood
                  Nagar,Chirala, Andhra Pradesh, Bapatla District- 523155,
                  India.
                </p>
              </a>
            </div>
          </div>
          <div
            className="col-lg-3 d-flex"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="info-box">
              <i className="bx bx-envelope"></i>
              <h3>Email Us</h3>
              <a href="mailto:thulasisolutions@outlook.com">
                thulasisolutions@outlook.com
              </a>
            </div>
          </div>
          <div
            className="col-lg-3 d-flex"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <div className="info-box ">
              <i className="bx bx-phone-call"></i>
              <h3>Call Us</h3>
              <a href="tel:+91 9963801122">+91 9963801122</a>
            </div>
          </div>
          <div className="col-lg-12" data-aos="fade-up" data-aos-delay="300">
            <form onSubmit={handleSubmit} className="php-email-form">
              <h3 style={{ textAlign: "center" }}>Leave a Message</h3>
              <div className="row">
                <div className="col-lg-6 form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    id="name"
                    onChange={handleChange}
                    value={formData.name}
                    placeholder="Your Name"
                    required
                  />
                </div>
                <div className="col-lg-6 form-group">
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    id="email"
                    onChange={handleChange}
                    value={formData.email}
                    placeholder="Your Email"
                    required
                  />
                </div>
              </div>
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  id="subject"
                  onChange={handleChange}
                  value={formData.subject}
                  placeholder="Subject"
                  required
                />
              </div>
              <div className="form-group">
                <textarea
                  className="form-control"
                  name="message"
                  rows="5"
                  onChange={handleChange}
                  value={formData.message}
                  placeholder="Message"
                  required
                ></textarea>
              </div>
              <div className="my-3">
                <div className="loading">Loading</div>
                <div className="error-message"></div>
                <div className="sent-message">
                  Your message has been sent. Thank you!
                </div>
              </div>
              <div className="text-center">
                <button type="submit">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
