import React, { useState } from "react";
import ImageViewer from "react-simple-image-viewer";
import UnderConstruction from "./UnderConstructionModal";
import { Link } from "react-router-dom";

const Careers = () => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const images = [
    "assets/img/career/InternsPoster.jpg",
    "assets/img/career/Interns Poster1.jpg",
  ];

  return (
    <div style={{ textAlign: "center" }}>
      {images.map((src, index) => (
        <img
          src={src}
          onClick={() => {
            setCurrentImage(index);
            setIsViewerOpen(true);
          }}
          width="500"
          key={index}
          style={{ margin: "20px" }}
          alt="posters"
        />
      ))}

      {isViewerOpen && (
        <ImageViewer
          src={images}
          currentIndex={currentImage}
          onClose={() => setIsViewerOpen(false)}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      {/* Render the UnderConstruction modal */}
      {showModal && <UnderConstruction onClose={() => setShowModal(false)} />}

      <div>
        {/* <h1 style={{ textAlign: "center" }} onClick={() => setShowModal(true)}>
          Apply Now
        </h1> */}
        {/* <Link to="/CandidateReg">
          <h1
            style={{ textAlign: "center" }}
            // onClick={() => setShowModal(true)}
          >
            Apply Now
          </h1>
        </Link> */}

        {/* Linking to Microsoft Form */}
        <Link to="https://forms.gle/T2ZTQp5WN2xtw48T8" target="_blank">
          <h1
            style={{ textAlign: "center" }}
            // onClick={() => setShowModal(true)}
          >
            Apply Now
          </h1>
        </Link>
      </div>
    </div>
  );
};

export default Careers;
