import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isTop, setIsTop] = useState(true);
  const [navbarMobile, setNavbarMobile] = useState(false);

  const scrollListener = () => {
    setIsTop(window.scrollY < 100);
  };

  useEffect(() => {
    window.addEventListener("scroll", scrollListener);

    return () => {
      window.removeEventListener("scroll", scrollListener);
    };
  }, []);

  return (
    <>
      <div id="index">
        <section id="topbar" className="d-flex align-items-center">
          <div className="container d-flex justify-content-center justify-content-md-between">
            <div className="contact-info d-flex align-items-center">
              <i className="bi bi-envelope-fill"></i>
              <a href="mailto:thulasisolutions@outlook.com">
                thulasisolutions@outlook.com
              </a>
              <i className="bi bi-phone-fill phone-icon "></i>
              <a href="tel:+91 9963801122">9963801122</a>
            </div>
            <div className="social-links d-none d-md-block">
              <button
                className="twitter"
                onClick={() => {
                  /* handle click */
                }}
              >
                <i className="bi bi-twitter"></i>
              </button>
              <button
                className="facebook"
                onClick={() => {
                  /* handle click */
                }}
              >
                <i className="bi bi-facebook"></i>
              </button>
              <button
                className="instagram"
                onClick={() => {
                  /* handle click */
                }}
              >
                <i className="bi bi-instagram"></i>
              </button>
              <button
                className="linkedin"
                onClick={() => {
                  /* handle click */
                }}
              >
                <i className="bi bi-linkedin"></i>
              </button>
            </div>
          </div>
        </section>
        <header
          id="header"
          className={`d-flex align-items-center ${isTop ? "" : "fixed-top"}`}
        >
          <div className="container d-flex align-items-center">
            <div className="logo me-auto">
              <h1>
                <a href="/">Thulasi Solutions</a>
              </h1>
            </div>
            <nav
              id="navbar"
              className={`navbar ${navbarMobile ? "navbar-mobile" : ""}`}
            >
              <ul>
                <li>
                  <NavLink
                    // exact
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/About"
                  >
                    About Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/Services"
                  >
                    Services
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/Clients"
                  >
                    Clients
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/Contact"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/Careers"
                  >
                    Careers
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeclassname="active"
                    className="nav-link scrollto"
                    to="/FormsLogin"
                  >
                    Login
                  </NavLink>
                </li>
              </ul>
              <i
                className={`bi ${
                  navbarMobile ? "bi-x" : "bi-list"
                } mobile-nav-toggle`}
                onClick={() => setNavbarMobile(!navbarMobile)}
              ></i>
            </nav>
          </div>
        </header>
      </div>
    </>
  );
};
export default Header;
