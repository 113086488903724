import React, { useState, useEffect,useCallback } from "react";
import { Link } from "react-router-dom";

const IndexHero = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const slides = [
    {
      image: "assets/img/slide/slide-1.jpg",
      title: "Welcome to Thulasi Solutions",
      description:
        "Thulasi Solutions is a forward-thinking software development company dedicated to providing high-quality services to its global clientele. We specialize in creating custom, scalable software solutions, meeting diverse business needs with a focus on latest technologies and innovation. Our team of skilled professionals strives to deliver top-notch software solutions, offering an amalgamation of robust development processes with transparent project management practices. With a commitment to customer satisfaction, we ensure tailor-made solutions that drive business growth. We are a reliable tech partner you can trust.",
    },
    {
      image: "assets/img/slide/slide-2.jpg",
      title: "Innovative Software Crafting",
      description:
        "At Thulasi Solutions, we believe in transforming your ideas into dynamic software solutions that drive growth. Our seasoned team of developers utilizes a blend of cutting-edge technology and proven strategies to design and implement software that aligns with your business objectives. We specialize in creating bespoke, scalable solutions, providing seamless user experiences and functionality. From the initial phase of understanding your requirements to final deployment and post-launch support, we are committed to delivering excellence at every step. Partner with us to harness the power of digital transformation.",
    },
    {
      image: "assets/img/slide/slide-3.jpg",
      title: "Software Development for Your Unique Needs",
      description:
        "At Thulasi Solutions, we prioritize our customers unique needs, delivering personalized software development services. We focus on crafting solutions that not only address specific business challenges but also provide a competitive edge in the marketplace. Our experienced team invests time to understand your specific requirements, industry dynamics, and user expectations. This ensures that our custom-made software solutions enhance operational efficiency and user engagement. We blend cutting-edge technology with customer-centric strategies to create products that facilitate growth and success. Choose Thulasi Solutions, your trusted partner for bespoke software development.",
    },
  ];

  const nextSlide = useCallback(() => {
    setCurrentSlide((currentSlide + 1) % slides.length);
  }, [currentSlide, slides.length]);

  const prevSlide = useCallback(() => {
    setCurrentSlide((currentSlide - 1 + slides.length) % slides.length);
  }, [currentSlide, slides.length]);

  useEffect(() => {
    const slideTimer = setInterval(nextSlide, 8000);

    return () => {
      clearInterval(slideTimer);
    };
  }, [nextSlide]);

  return (
    <section id="hero">
      <div className="hero-container">
        <div id="heroCarousel" className="carousel slide carousel-fade">
          <div className="carousel-inner" role="listbox">
            {slides.map((slide, index) => (
              <div
                key={index}
                className={`carousel-item ${index === currentSlide ? "active" : ""}`}
                style={{ backgroundImage: `url(${slide.image})` }}
              >
                <div className="carousel-container">
                  <div className="carousel-content container">
                    <h2 className="animate__animated animate__fadeInDown">
                      {slide.title}
                    </h2>
                    <p
                      className="animate__animated animate__fadeInUp"
                      style={{ textAlign: "justify" }}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;{slide.description}
                    </p>
                    <Link to="/About"
                      className="btn-get-started animate__animated animate__fadeInUp scrollto"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
             <button
              className="carousel-control-prev"
              onClick={prevSlide}

            >
              <span
                className="carousel-control-prev-icon bi bi-chevron-left"
                aria-hidden="true"
              ></span>
            </button>
            <button
              className="carousel-control-next"
              onClick={nextSlide}

            >
              <span
                className="carousel-control-next-icon bi bi-chevron-right"
                aria-hidden="true"
              ></span>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default IndexHero;
