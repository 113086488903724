import React from "react";
import { useState, useEffect } from "react";
import image404 from "./Images/Construction.gif";
const modalStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  backgroundColor: "#C5DFF8",
  zIndex: 1000,
  padding: "1em",
  borderRadius: "45px",
  maxWidth: "50%",
  maxHight: "10em",
};

// console.log("Modal is being rendered");

export default function Modal({ onClose }) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const imageStyles =
    windowWidth <= 768
      ? { height: "80%", width: "100%" }
      : { height: "95%", width: "80%" };

  const buttonStyles =
    windowWidth <= 768
      ? { width: "60%", marginLeft: "auto", marginRight: "auto" }
      : { width: "30%", marginLeft: "auto", marginRight: "auto" };

  return (
    <div style={modalStyles} className="row">
      <center>
        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2>Page is Under Construction</h2>
            </div>
            <div className="row d-flex justify-content-center">
              <h1 style={{ textAlign: "center" }}>
                Content will be Available Soon.
              </h1>
              <img
                alt="Error"
                src={image404}
                style={{ height: "20rem", width: "20rem", imageStyles }}
              />
              <h1 style={{ textAlign: "center" }}>
                Thanks for your Patience !
              </h1>
            </div>
          </div>
        </section>
        <button onClick={onClose} className="btn btn-info" style={buttonStyles}>
          Close
        </button>
      </center>
    </div>
  );
}
<div></div>;
